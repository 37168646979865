import React, {CSSProperties, ReactNode, useRef} from "react";
import classNames from "classnames";
import './beer-glass-loader.scss'
import {getRndInteger} from "../../../controller/Utils";


interface Props {
    className?: string
    style?: CSSProperties;
}



export default function BeerGlassLoader(props: Props) {
    const parentDiv = useRef(null);

    const classes = classNames(props.className, 'beer-glass-loader-holder');

    const getBubbles = () => {
        const bubbles: ReactNode[] = [];
        const numberBubbles = getRndInteger(10, 15);
        for (let i = 0; i < numberBubbles; i++) {
            bubbles.push(<g className={'bubble'} key={i}>
                <circle cx={getRndInteger(200, 400)} cy={getRndInteger(600, 1000)} r={getRndInteger(2, 5)}/>
            </g>)
        }
        return bubbles;
    };

    return (
        <div style={props.style} className={classes} ref={parentDiv}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width='100%' viewBox={'0 0 640 640'} preserveAspectRatio="none">
                <defs>
                    <clipPath id={'glass'}>
                        <path xmlns="http://www.w3.org/2000/svg" d="M243.14 135.04C237.82 136.22 235.83 137.76 236.62 140.08C236.87 140.82 236.9 141.43 236.68 142C235.64 144.99 231.88 168.74 230.9 178.21C229.5 192.32 229.25 197.97 229.25 216.18C229.22 234.48 229.47 239.86 230.9 252.96C234.21 282.9 242.95 316.76 253.2 339.27C256.35 346.16 258.37 353.01 259.5 360.47C260.27 365.71 260.63 375.79 260.63 392.89C260.63 413.99 259.99 428.33 258.28 444.29C256.93 456.58 254.39 473.21 253.23 477.21C251.98 481.48 251.79 486.11 252.77 489.71C253.78 493.37 257.57 497.47 262.62 500.33C274.12 506.81 295.2 509.93 324.68 509.53C350.01 509.2 365.51 507.01 376.65 502.24C384.14 499.02 388.97 494.01 390.44 487.96C391.24 484.8 390.99 482.32 389.37 476C386.22 463.87 383.9 448.45 382.03 427.83C380.84 414.79 380.5 406.19 380.26 385.16C380.07 365.91 380.2 362.99 381.57 355.43C382.8 348.78 384.75 342.73 388.09 335.34C393.93 322.44 398.27 308.97 402.8 289.75C404.63 282.09 405.25 278.8 406.62 269.76C409.56 250.58 410.75 234.38 410.75 214.19C410.75 194.91 409.77 181.81 407.08 165.14C406.16 159.53 403.84 147.37 402.98 143.88C402.62 142.37 402.62 141.96 403.04 140.69C403.44 139.41 403.44 139.11 403.07 138.27C402.1 136.08 398.76 135.11 387.51 133.7C365.94 131.04 339.85 130.04 306.91 130.57C281.68 130.98 252.13 133.06 243.14 135.04Z" id="dmxA1g1Rv"/>
                    </clipPath>
                </defs>
                <g id={'glass-clip'} clipPath={'url(#glass)'}>
                    <g>
                        <rect id={'liquid'} className={'fill'} x={0} height={640} width={640}/>
                    </g>
                    {getBubbles()}
                </g>
                <g id={'glass-border'} fillOpacity={0} fill={'#000000'} stroke={'#000000'} strokeWidth={5} strokeOpacity={1}>
                    <path xmlns="http://www.w3.org/2000/svg" d="M243.14 135.04C237.82 136.22 235.83 137.76 236.62 140.08C236.87 140.82 236.9 141.43 236.68 142C235.64 144.99 231.88 168.74 230.9 178.21C229.5 192.32 229.25 197.97 229.25 216.18C229.22 234.48 229.47 239.86 230.9 252.96C234.21 282.9 242.95 316.76 253.2 339.27C256.35 346.16 258.37 353.01 259.5 360.47C260.27 365.71 260.63 375.79 260.63 392.89C260.63 413.99 259.99 428.33 258.28 444.29C256.93 456.58 254.39 473.21 253.23 477.21C251.98 481.48 251.79 486.11 252.77 489.71C253.78 493.37 257.57 497.47 262.62 500.33C274.12 506.81 295.2 509.93 324.68 509.53C350.01 509.2 365.51 507.01 376.65 502.24C384.14 499.02 388.97 494.01 390.44 487.96C391.24 484.8 390.99 482.32 389.37 476C386.22 463.87 383.9 448.45 382.03 427.83C380.84 414.79 380.5 406.19 380.26 385.16C380.07 365.91 380.2 362.99 381.57 355.43C382.8 348.78 384.75 342.73 388.09 335.34C393.93 322.44 398.27 308.97 402.8 289.75C404.63 282.09 405.25 278.8 406.62 269.76C409.56 250.58 410.75 234.38 410.75 214.19C410.75 194.91 409.77 181.81 407.08 165.14C406.16 159.53 403.84 147.37 402.98 143.88C402.62 142.37 402.62 141.96 403.04 140.69C403.44 139.41 403.44 139.11 403.07 138.27C402.1 136.08 398.76 135.11 387.51 133.7C365.94 131.04 339.85 130.04 306.91 130.57C281.68 130.98 252.13 133.06 243.14 135.04Z" id="dmxA1g1Rv"/>
                </g>
            </svg>
        </div>
    )
}